import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { ru, ru_eda, en, am, uz} from "./localizations"

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en,
      ru,
      ru_eda,
      am,
      uz
    },
    fallbackLng: "ru",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n
