import { EntityId } from "../../../features/entity/type"
import { Ticket } from "../../../models/ticket";

export interface IHubsReportItemp {
  hub_id: EntityId
  hub_display_name: string
  city_id: EntityId
  city_display_name: string
  total: Number
  finished: Number
  cancelled: Number
  started: Number
  enqueued: number
  first_name?: string
  last_name?: string
  ticket?: Ticket
  ticket_time: number,
  line_display_name: string,
  user_id: EntityId,
  user_name: string,
  phone: string,
  tag: string,
  work_time: Date,
  wait_time: Date,
  summ_time: Date,
  task_id: string,
  avg_ticket_time: Number,
  avg_closed_tickets: Number
}

export type TStatisticData = {
  reports: IHubsReportItemp[],
  task_id: string
}

export type StatisticState = {
  taskId: string,
  data?: TStatisticData,
  isLoading: boolean
}

export const LOAD_REPORT = "ST_LOAD_REPORT"
export const END_LOAD_REPORT = "ST_END_LOAD_REPORT"
export const SET_TASK_ID = "SET_TASK_ID"

export interface ILoadReportAction {
  type: typeof LOAD_REPORT,
  typeStatistics: string,
  params: any
}

export const loadReportAction = (typeStatistics: string, params: any): ILoadReportAction => {
  return {
    type: LOAD_REPORT,
    typeStatistics: typeStatistics,
    params: params
  }
}

export enum TypeStatistics {
  EXPERTS_LOAD = 'experts_load',
  TICKETS_BY_EXPERTS = 'tickets_by_experts',
  TICKETS_BY_HUBS = 'tickets_by_hubs',
  TICKETS = 'tickets',
  TICKETS_CREATED_BY_EXPERTS = 'tickets_created_by_experts',
  WORK_SESSIONS_BY_EXPERTS = 'work_sessions_by_experts',
  WORK_SESSIONS_BY_HUBS = 'work_sessions_by_hubs'
}

export interface IStatisticsAction {
  type: typeof END_LOAD_REPORT,
  response: any,
  isLoading: boolean
}

export interface ISetTaskAction {
  type: typeof SET_TASK_ID,
  taskId: string
}

export const endLoadReportAction = (data: any, isLoading: boolean): IStatisticsAction => {
  return {
    type: END_LOAD_REPORT,
    response: data,
    isLoading: isLoading
  }
}

export const setTaskAction = (taskId: string): ISetTaskAction => {
  return {
    type: SET_TASK_ID,
    taskId: taskId
  }
}