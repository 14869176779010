import React, { forwardRef, useEffect, useState } from "react";
import Template from "./step_template";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnotherLineManualLineAction,
  stopWorkSessionAction,
} from "../store";
import { Typography, Button, Box, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MaterialTable, { Icons } from "material-table";
import { getArrowTable } from "../../../../assets/icons";
import { Ticket } from "../../../../models/ticket";
import { AppState } from "../../../store";
import { stringSort } from "../../../../models/ticket/components/table";
import { CPagination } from "../../../../features/entity";
import { getManualLineTicketsAction, selectTicketAction } from "../saga";
import clockIcon from "../../../../assets/clock.svg";
import { Line, RemoteSyncLineContainer } from "../../../../models/line";
import { TicTag, RemoteSyncTicTagContainer } from "../../../../models/tic_tag";
import CommentsDialog from "../comments_dialog";
import { truncate } from "../../../../shared/helper";
import OtherLineDialog from "./other_line_dialog";
import { EntityId } from "../../../../features/entity/type";
import PopoverManualLine from "./popover_manual_line";
const stateTickets = "registered";

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedTicket, setSelectedTicket] = useState();
  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
  const [dialogOtherLineOpen, setDialogOtherLineOpen] = useState(false);
  const [ticketIdForLine, setTicketIdForLine] = useState();

  const { tickets, lineId, line, tags } = useSelector((state: AppState) => ({
    tickets: Object.keys(state.tickets.entities).map(
      (k) => state.tickets.entities[k] as Ticket
    ),
    lineId: state.expertPage.selected_desk?.line_id,
    line: Object.keys(state.lines.entities)
      .map((k) => state.lines.entities[k] as Line)
      .find((line) => line.id === state.expertPage.selected_desk?.line_id),
    tags: Object.keys(state.tags.entities).map(
      (k) => state.tags.entities[k] as TicTag
    ),
  }));

  const stopWorkSessionClick = () => dispatch(stopWorkSessionAction());
  const header = (
    <Typography variant="h5">
      {`${t("Wait for client assignment")} - ${
        line?.mode !== "manual" ? t("auto") : t("manual")
      }`}
    </Typography>
  );
  const bottom = (
    <Button
      variant="outlined"
      color="default"
      qa-tag="expert-desk-end-button"
      className="default-button"
      onClick={stopWorkSessionClick}
    >
      {t("End work")}
    </Button>
  );

  function toHHMMSS(sec: number) {
    let sec_num = Math.floor(sec); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    let sHours = String(hours);
    let sMinutes = String(minutes);
    let sSeconds = String(seconds);

    if (hours < 10) {
      sHours = "0" + sHours;
    }
    if (minutes < 10) {
      sMinutes = "0" + sMinutes;
    }
    if (seconds < 10) {
      sSeconds = "0" + sSeconds;
    }
    return sHours + ":" + sMinutes + ":" + sSeconds;
  }

  const isRedColor = (seconds: number | null): boolean => {
    if (!seconds) {
      return false;
    }

    if (seconds / 60 >= 25) {
      return true;
    } else {
      return false;
    }
  };

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref)),
  };

  useEffect(() => {
    if (lineId && line?.mode == "manual")
      dispatch(
        getManualLineTicketsAction(lineId, stateTickets, "with_comments")
      );
  }, [lineId, line]);

  const selectTicket = (ticketId: string) => {
    dispatch(selectTicketAction(ticketId));
  };

  const stretcher = (
    <img src={clockIcon} alt="wait ticket" qa-tag="wait-ticket-icon" />
  );

  const onShowCommentsDialog = (ticket: Ticket) => {
    setSelectedTicket(ticket);
    setOpenCommentsDialog(true);
  };

  const onForwarding = (lineId: EntityId, ticketId: EntityId) => {
    dispatch(selectAnotherLineManualLineAction(lineId, ticketId));
    setTimeout(() => window.location.reload(), 500);
  };

  console.log(tickets);

  return (
    <Template
      header={header}
      stretcher={
        line?.mode !== "manual" || tickets.length === 0 ? stretcher : undefined
      }
      bottom={bottom}
    >
      <>
        <RemoteSyncLineContainer />
        <RemoteSyncTicTagContainer />
        {line?.mode === "manual" && tickets.length !== 0 ? (
          <Box display="flex" flexWrap="wrap" className="table-wait">
            <MaterialTable
              icons={tableIcons}
              style={{ width: "100%" }}
              columns={[
                {
                  field: "ticket_no",
                  title: t("ticket_no"),
                },
                {
                  title: t("name client"),
                  customSort: (data1, data2) =>
                    stringSort(
                      `${(data1 as Ticket).client_data?.last_name || ""} ${
                        (data1 as Ticket).client_data?.first_name || ""
                      }`,
                      `${(data2 as Ticket).client_data?.last_name || ""} ${
                        (data2 as Ticket).client_data?.first_name || ""
                      }`
                    ),
                  render: (row) => (
                    <span>
                      {((row as Ticket).client_data?.last_name || "") +
                        " " +
                        ((row as Ticket).client_data?.first_name || "")}
                    </span>
                  ),
                },
                {
                  title: t("phone"),
                  field: "phone",
                  render: (row) => (
                    <span qa-tag="mq-phone">
                      {(row as Ticket).client_data?.phone || ""}
                    </span>
                  ),
                },
                {
                  title: t("total_time"),
                  field: "created_at",
                  defaultSort: "asc",
                  render: (row) => (
                    <span className="total_time">
                      <span
                        style={
                          isRedColor((row as Ticket).ticket_lifetime)
                            ? { color: "#FF0000" }
                            : {}
                        }
                      >
                        {toHHMMSS((row as Ticket).ticket_lifetime)}
                      </span>
                      {(row as Ticket).time_in_queue && (
                        <span>
                          {" ( " +
                            toHHMMSS((row as Ticket).time_in_queue) +
                            " )"}
                        </span>
                      )}
                    </span>
                  ),
                },
                {
                  title: t("tag"),
                  render: (row) => (
                    <span>
                      {
                        tags?.find((tag) => tag.id === (row as Ticket).tag_id)
                          ?.display_name
                      }
                    </span>
                  ),
                },
                {
                  title: t("comment"),
                  render: (row) => (
                    <Box
                      className="comment-text-short"
                      onClick={() => {
                        onShowCommentsDialog(row as Ticket);
                      }}
                    >
                      {truncate((row as Ticket).comments[0]?.message || "", 20)}
                    </Box>
                  ),
                },
                {
                  field: "action",
                  title: t("action"),
                  sorting: false,
                  render: (row) => (
                    <Box display="flex" flexDirection="row">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className="select-ticket-button"
                        onClick={() => selectTicket((row as Ticket).id)}
                      >
                        {t("select")}
                      </Box>
                      <Box>
                        <PopoverManualLine test-dataid="ep-popover-button">
                          <>
                            <MenuItem
                              qa-tag="mq-forward-btn"
                              onClick={() => {
                                setTicketIdForLine((row as Ticket).id);
                                setDialogOtherLineOpen(true);
                              }}
                            >
                              {t("Forward to other queue")}
                            </MenuItem>
                          </>
                        </PopoverManualLine>
                      </Box>
                    </Box>
                  ),
                },
              ]}
              data={tickets}
              options={{
                toolbar: false,
              }}
              components={{
                Pagination: (props) => <CPagination {...props} />,
              }}
            />
            <CommentsDialog
              ticket={selectedTicket}
              open={openCommentsDialog}
              setOpen={setOpenCommentsDialog}
            />
            <OtherLineDialog
              onForwardTo={(lineId) => onForwarding(lineId, ticketIdForLine)}
              ticketId={ticketIdForLine}
              open={dialogOtherLineOpen}
              setOpen={setDialogOtherLineOpen}
            />
          </Box>
        ) : (
          <Box />
        )}
      </>
    </Template>
  );
};
