import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import {getHoursWork} from "../../dashboard/helper";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles, createStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
//
const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '20px',
      fontWeight: 700,
    }
  })
)

type Props = {
  data: {
	key: string;
    name: string
	fullname: string
    value: number
    color: string
  }[];
  title: string | any;
  col1: string | any;
  col2: string | any;
  convertRow2Data: boolean
}

export default ({ data, title, col1, col2, convertRow2Data = true }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const pieData = data.filter((item) => item.value > 0).map((d) => ({name: d.name, value: (typeof d.value === 'string' || d.value instanceof String) ? Number(d.value) : d.value, color: d.color}))

  return <Card variant="outlined">
    <CardHeader title={title} classes={classes} style={{backgroundColor: "#F4F4F4", borderBottomWidth: "2px", borderBottomStyle: "solid", borderBottomColor: "#ddd" }}/>
    <CardContent>
      <Box display="flex" flexDirection="row">
        <PieChart width={320} height={320}>
          <Pie data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={30} outerRadius={135} fillRule="color">
            {
              pieData.map((entry, index) => <Cell key={index} fill={entry.color} />)
            }
          </Pie>
        </PieChart>

        <Divider orientation="vertical" flexItem className="dash-pie-divider" />
        <Box flexGrow={1}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="dash-color-marker-col"/>
                <TableCell>{col1}</TableCell>
                <TableCell>{col2}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className="dash-color-marker-col" component="th" scope="row">
                    <Box className="dash-color-marker" style={{ backgroundColor: row.color }} />
                  </TableCell>
                  <TableCell>{row.fullname}</TableCell>
                  <TableCell>{(row?.value && convertRow2Data) ? getHoursWork(row.value || 0, t('h'), t('min'), t('sec')) : row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </CardContent>
  </Card>
}