import {EntityState} from "../features/entity/type";

const initStateLanguages: EntityState<any> = {
  entities: {0: {id: "ru", lang: "ru"}, 1: {id: "en", lang: "en"}, 2: {id: "am", lang: "am"}, 3: {id: "ru_eda", lang: "ru_eda"}, 4: {id: "uz", lang: "uz"} }
}

export function LanguagesReducer(state: EntityState<any> = initStateLanguages, action: any): EntityState<any> {
  switch (action.type) {
    default:
      return state
  }
}
