export default {
  translations: {
    "current lang": "en",

    too_many_pre_entries_per_day: "You have already been recorded before",

    "Repeated input of phone!": "This number was already entered",

    webpush_problem: "Turn on notifications in your browser",

    "Input other number": "Enter another number",

    Cancel: "Cancel",

    Start: "Get started",

    "Start queue handling, when you will be ready":
      "When you are ready, click start processing",

    "Start queue handling": "Start processing",

    "Alarm button": "Alarm button",

    "Change desk": "Change window",

    "Please, choose your table": "Select your window",

    "Before the handling": "Get to work",

    "Expert work desk": "Expert desktop",

    Next: "Continue",

    "End work": "Finish work",

    "Wait for client assignment": "Waiting",

    "Client incoming": "Client showed up",

    "Wait for client": "Сlient has been assigned to you",

    "No client": "Return to queue",

    Recall: "Call again",

    "Next client": "Next",

    "Finish and interupt": "Finish and stop",

    "Forward to other queue": "Forward",

    "Return to queue": "Return to queue",

    "Freeze client": "Freeze ticket",

    "Session data": "Session data",

    visit_history: "Visit history",

    created_by_terminal: "Created in terminal",

    enqueued: "In queue",

    started: "Launching",

    assigned: "Assigned",

    finished: "Finished",

    cancelled: "Cancelled",

    forwarded: "Forwarded",

    hold: "Holded",

    holded: "Holded",

    recalled: "Сalled again",

    returned: "Returned",

    checked_in: "Verified",

    "Please, select the line": "Select the line",

    search: "Search",

    display_name: "Name",

    lang: "Language",

    phone_code: "Country dialing code",

    add: "Add",

    assignment: "Assign",

    configurate: "Edit",

    time_slots: "Working hours",

    devices: "Devices",

    address: "Address",

    city_id: "City",

    role_id: "Role",

    name: "User",

    from: "From",

    till: "To",

    hour_short: "Hours",

    min_short: "Minutes",

    human_short: "Peoples",

    Period: "Period",

    Capacity: "Clients",

    "Time slots": "Hub opening hours",

    kind: "Device type",

    state: "Status",

    activation_code: "Activation code",

    Fields: "Fields",

    default: "Default",

    enabled: "Activated",

    Admin: "Administrator",

    electric_queue: "Electronic queue",

    ticket: "Ticket",

    desk: "Window",

    waiting: "Waiting",

    expert: "Expert",

    Expert: "Expert",

    city: "City",

    hub: "Hub",

    experts: "Experts",

    to: "To",

    "Tickets by hubs": "History of visits by hubs",

    "Tickets by experts": "History of visits by experts",

    user_id: "User",

    hub_id: "Hub",

    total: "Total",

    line_id: "Line",

    avatar: "Avatar",

    email: "Email",

    desks: "Windows",

    password: "Password",

    ticket_no: "Ticket",

    line: "Line",

    "Work session by experts": "Working time control by experts",

    "Work session by hubs": "Working time control by hubs",

    date: "Date",

    closed_at: "Сompletion",

    "Expert load": "Experts workload",

    interaction_time: "Interaction",

    "block/reset": "Block/Reset",

    created_at: "Created",

    started_at: "Started",

    finished_at: "Finished",

    wait_time: "Waiting time",

    phone: "Phone number",

    tickets: "Tickets",

    dashboard: "Overview",

    "tickets done": "Clients served",

    "waiting time": "Waiting time in line",

    "ticket topic": "Theme",

    clients: "Clients",

    "expert work": "Expert status",

    work_time: "Service Time",

    working: "Service",

    created: "Created",

    "tickets in work": "Clients are served",

    "tickets wait": "Clients waiting",

    "manager in work": "Experts busy",

    today: "Today",

    yesterday: "Yesterday",

    week: "Week",

    month: "Month",

    client: "Client",

    save: "save",

    "cancel ticket": "Cancel",

    "help request": "Need help",

    need_help_on_desk: "Need help at window no.",

    Manager: "Manager",

    back: "Back",

    lines: "Lines",

    towns: "Cities",

    hubs: "Hubs",

    users: "Users",

    templates: "Templates",

    "ticket was changed": "Ticket has been changed",

    high: "Highest",

    elevated: "High",

    normal: "Medium",

    "loyalty level": "Loyalty status",

    "create ticket": "Create a ticket",

    create: "Create",

    tags: "Tags",

    tag_ids: "Tags",

    tag: "Tag",

    service: "Service",

    statistics: "Reports",

    "new ticket": "Add new ticket",

    admin: "Settings",

    tv_display_name: "Display name",

    logout: "Log out",

    settings: "Settings",

    "electronic queue enrollment": "Electronic queue enrollment",

    "to get in line": "To get in line",

    "sign up at another time": "Sign up at another time",

    "type phone": "Type phone",

    "type first name": "Type first name",

    "type second name": "Type second name",

    next: "Next",

    "hello!": "Hello!",

    "you have joined the queue": "You have joined the queue",

    "take the ticket": "Take the ticket",

    "thank you!": "Thank you!",

    "you have successfully signed up for the driver center":
      "You have successfully signed up for the driver center",

    "date time": "Date time",

    signup: "Sign up",

    russian: "Russian",

    russian_eda: "Russian (eda)",

    english: "English",

    armenian: "Armenian",

    "Please, choose your language": "Please, choose your language:",

    Detail: "Detail",

    "by hubs": "By hubs",

    "visit history": "Visit history",

    "work time conotrol": "Work time conotrol",

    "by experts": "By experts",

    "expert report": "Expert report",

    "select all": "Select all",

    "select nothing": "Select nothing",

    "wihout tag": "Without tag",

    first_name: "First name",

    last_name: "Last name",

    created_at_date: "Created",

    total_time: "Total time",

    session: "Session",

    "service history": "Service history",

    data: "Data",

    driver: "Visitor",

    look: "Look",

    origin: "Origin",

    end: "End",

    select_date_and_time: "Select date and time",

    "start period": "Start period",

    "end period": "End period",

    served: "Served",

    "did not come": "Did not come",

    "total tickets": "Total tickets",

    "max service time": "Maximum service time",

    "average service time": "Average service time",

    "min service time": "Minimum service time",

    h: "h",

    min: "min",

    sec: "sec",

    "work time": "Work time",

    "name client": "Visitor",

    "took to work": "Took to work",

    completed: "Completed",

    "history change": "Change history",

    "changes saved successfully": "Changes saved successfully",

    "reset date": "Reset date",

    "error required fields": "Required fields are empty or incorrect!",

    "input data in ticket": "Enter the details to create a new ticket",

    "load yet": "Load more",

    "close session": "Close session",

    "success close session": "Success close session",

    "failed close session": "Failed close session",

    "tickets created by experts": "Tickets created for users",

    "by users": "By users",

    "select ticket": "Select ticket",

    select: "Select",

    mode: "Mode",

    link: "Link",

    "waiting time per day": "Waiting time per day",

    "ticket processing time per day": "Ticket processing time per day",

    "total working hours per day": "Total working hours per day",

    auto: "Automatic queue",

    manual: "Manual queue",

    cancelled_by_system: "Cancelled by system",

    "need help": "Need help!",

    apply: "Apply",

    comment: "Comment",

    "comment created": "Comment created",

    "comment edited": "Comment edited",

    "comment deleted": "Comment deleted",

    comments: "Comments",

    change: "Change",

    delete: "Delete",

    sms: "Config sms",

    message: "Message",

    sender: "Sender",

    "delay in minutes": "Delay (in minutes)",

    registered: "Registered",

    "enter time in minutes": "Enter time in minutes",

    terminal: "Terminal",

    "select line": "Select line",

    "time wait in line": "Waiting time in line",

    "min time wait": "Minimum waiting time",

    "avg time wait": "Average waiting time",

    "max time wait": "Maximum waiting time",

    "time process ticket in line": "Queue processing time",

    "min time process": "Minimum processing time",

    "avg time process": "Average processing time",

    "max time process": "Maximum processing time",

    action: "Action",

    print: "Print",

    hello: "Hello",

    "you signed up": "You signed up:",

    "your ticket number": "Your number in the queue:",

    "take the coupon and wait for you to be called":
      "Take the coupon and wait for you to be called.",

    city_with_nubs_destroy: "You cannot delete a city if it is linked to hubs",

    hub_with_ticket_destroy:
      "You cannot delete a hub if it has unfinished tickets",

    hub_with_work_sessions_destroy:
      "You cannot delete a hub if it has open sessions",

    line_with_tickets_destroy:
      "You cannot delete a queue if it has unfinished tickets",

    desk_with_ticket_destroy:
      "You cannot delete a window if it has a ticket in service",

    user_with_work_session_destroy:
      "You cannot delete a user if he has open sessions",

    record_not_unique: "You cannot add more than one comment per action",

    "oops an error occured": "Oops, an error occured!",

    "link in sms": "Link in sms",

    tv: "Television",

    "repeat call": "You can repeat the call through:",

    platinum_gold: "Platinum / Gold",

    silver_bronze: "Silver / Bronze",

    without_status: "Without status",

    status_loyalty: "Your loyalty status",

    invite: "Invite",
  },
};
