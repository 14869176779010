import React, {useState, useEffect, forwardRef} from 'react'
import { Container, Paper, Typography, Box, Divider } from '@material-ui/core'
import { RemoteSyncCityContainer, City } from '../../../../models/city'
import { RemoteSyncHubContainer } from '../../../../models/hub'
import { RemoteSyncLineContainer, Line } from '../../../../models/line'
import { RemoteSyncDeskContainer } from '../../../../models/desk'
import { AppState } from '../../../store'
import { useSelector, useDispatch } from 'react-redux'
import { EntityId } from '../../../../features/entity/type'
import MultipleField from '../components/multiple'
import PieChart from '../components/pie'
import DatePicker from '../../../../config/pages/statistics/components/daterange'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { RemoteSyncUserContainer } from '../../../../models/user'
import MaterialTable, {Icons} from 'material-table'
import CPagination from '../../../../features/entity/components/pagination'
import { fetchRequest } from '../../../../features/entity/store/actions'
import {endLoadReportAction, IHubsReportItemp, loadReportAction, TypeStatistics} from '../type'
import {
  useStyles,
  bildUrl,
  tableOptions,
  userSelector,
  cityFilter,
  timeHHMM,
  loadFilterMultitipleFromLocalStorage, TypeFilter, NameFilter, loadFilterToLocalStorage, containsIds, colorizeData
} from './helper'
import {DateRange} from "@material-ui/pickers";
import {getArrowTable, getDownloadIcon} from "../../../../assets/icons";
import {getHoursWork} from "../../dashboard/helper";

export default () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { cities, users, lines } = useSelector((state: AppState) => ({
    cities: Object.keys(state.cities.entities).map(k => state.cities.entities[k] as City),
    users: userSelector(state),
    lines: Object.keys(state.lines.entities).map(k => state.lines.entities[k] as Line)
  }))

  const isLoading = useSelector((state: AppState) => state.statistics.isLoading)

  const [data, setData] = useState<IHubsReportItemp[]>([])

  const dataNew = useSelector((state: AppState) =>
    state.statistics.taskId == state.statistics.data?.task_id ? state.statistics.data.reports : [])

  useEffect(() => {
    setData(dataNew)
  }, [dataNew.length])

  const cityIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Worksession_expert, NameFilter.City)
  const lineIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Worksession_expert, NameFilter.Line)
  const userIdsLS = loadFilterMultitipleFromLocalStorage(TypeFilter.Worksession_expert, NameFilter.User)

  const [cityIds, setCityIds] = useState<EntityId[]>(cityIdsLS)
  const [lineIds, setLineIds] = useState<EntityId[]>(lineIdsLS)
  const [userIds, setUserIds] = useState<EntityId[]>(userIdsLS)

  const [date, setDate] = useState<DateRange<Date>>([new Date(), new Date()])

  const dispatch = useDispatch()

  const getIds = () => {
    let usersForCity = cityFilter(users, cityIds).map(e => e.id)
    let userIdsForCity = containsIds(userIds, usersForCity)
    setUserIds(userIdsForCity)

    return userIdsForCity
  }

  const valideInputs = userIds.length > 0 && lineIds.length > 0 && users.length > 0
  useEffect(() => {
    let userIdsForCity: string[] = []
    if (cityIds.length > 0 && userIds.length > 0 && users.length > 1)
      userIdsForCity = getIds()
    if (valideInputs && userIdsForCity.length > 0)
      dispatch(loadReportAction(TypeStatistics.WORK_SESSIONS_BY_EXPERTS, {
        user_ids: userIdsForCity,
        line_ids: lineIds,
        since: moment(date[0]).format("YYYY-M-D"),
        till: moment(date[1]).format("YYYY-M-D")
      }))
    else
      dispatch(endLoadReportAction([], false))
  }, [cityIds.length, userIds.length, lineIds.length, date[0], date[1], users.length])

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Worksession_expert, NameFilter.City, cityIds)
  }, [cityIds.length])

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Worksession_expert, NameFilter.User, userIds)
  }, [userIds.length])

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Worksession_expert, NameFilter.Line, lineIds)
  }, [lineIds.length])

  const columns = [
    {
      field: "user_name",
      title: t("user_id")
    },
    {
      field: "hub_display_name",
      title: t("hub_id")
    },
    {
      title: t("date"),
      field: "opened_at",
      render: (rowData:any) => <>{moment(rowData.opened_at).format("DD.MM.YY")}</>
    },
    {
      title: t("started_at"),
      field: "opened_at",
      render: (rowData:any) => <>{timeHHMM(rowData.opened_at)}</>
    },
    {
      title: t("closed_at"),
      field: "closed_at",
      render: (rowData:any) => <>{timeHHMM(rowData.closed_at)}</>
    },
    {
      title: t("waiting time per day"),
      field: "wait_time",
      render: (rowData:any) => <>{getHoursWork(rowData?.wait_time || 0, t('h'), t('min'), t('sec'))}</>
    },
    {
      title: t("ticket processing time per day"),
      field: "work_time",
      render: (rowData:any) => <>{getHoursWork(rowData?.work_time || 0, t('h'), t('min'), t('sec'))}</>
    },
    {
      title: t("total working hours per day"),
      field: "summ_time",
      render: (rowData:any) => <>{getHoursWork(rowData?.summ_time || 0, t('h'), t('min'), t('sec'))}</>
    }
  ]
  const xlsxClick = () => {
    dispatch(fetchRequest(bildUrl("statistics/work_sessions_by_experts.xlsx", {
      user_ids: userIds,
      line_ids: lineIds,
      since: moment(date[0]).format("YYYY-M-D"),
      till: moment(date[1]).format("YYYY-M-D"),
      lang: i18n.language
    }), {
      method: "GET"
    }, "DOWNLOAD"))
  }

  const tableIcons: Icons = {
    SortArrow: forwardRef((props, ref) => getArrowTable(props, ref))
  }

  const userGroupedData = (data) => {
	const groupedData = {}

	data.map(item => {
		if (groupedData?.[item['user_id']]?.['value']) {
			groupedData[item['user_id']]['value'] = groupedData[item['user_id']]['value'] + item.avg_ticket_time
			groupedData[item['user_id']]['count'] = Number(groupedData[item['user_id']]['count']) + 1
		}
		else {
			groupedData[item['user_id']] = {}
			groupedData[item['user_id']]['count'] = 1
			groupedData[item['user_id']]['value'] = item.avg_ticket_time
			groupedData[item['user_id']]['name'] = item.user_name
			groupedData[item['user_id']]['id'] = item.user_id
		}
	})

	const newArray = []

	Object.keys(groupedData).forEach(function (key) {
		const avg_ticket_time = groupedData[key]['value'] / groupedData[key]['count']

		newArray.push({
			key: groupedData[key]['id'],
			name: groupedData[key]['id'],
			fullname: groupedData[key]['name'],
			avg_ticket_time: Number.isInteger(avg_ticket_time) ? avg_ticket_time : avg_ticket_time.toFixed(2)
		})
	});

	return newArray
  }

  const pieData = data ? colorizeData(userGroupedData(data)?.map(d => ({ key: d.key, name: d.name, fullname: d.fullname, value: d.avg_ticket_time }))) : []
  
  return <>

    <RemoteSyncCityContainer />
    <RemoteSyncHubContainer />
    <RemoteSyncLineContainer />
    <RemoteSyncDeskContainer />
    <RemoteSyncUserContainer />
    <Container maxWidth="lg" className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h5" className="statistic-title">
          <b>{t("Work session by experts")}</b>
        </Typography>
        <Box display="flex" flexDirection="row" className="box-select-statistics">
          <MultipleField setVal={setCityIds} val={cityIds} collection={cities} label={t("city")} />
          <MultipleField setVal={setLineIds} val={lineIds} collection={lines} label={t("line")} />
          <MultipleField setVal={setUserIds} val={userIds} collection={cityFilter(users, cityIds)} label={t("experts")} />
          <DatePicker setVal={setDate} val={date} />
          <Box flexGrow={1} />
          <Box className="st-xlsx-button st-icon" onClick={() => { if (valideInputs) xlsxClick()} }>
            {getDownloadIcon(!valideInputs)}
          </Box>
        </Box>
		<Box display="flex" flexDirection="row" className="dash-two-in-row" style={{marginBottom: 42}}>
			<PieChart data={pieData}  title={t("avg ticket time")} col1={t("expert")} col2={t("avg time")} />
		</Box>
        <Divider className="table-margin-statistics"/>
        <Box  className="table-margin-statistics">
          <MaterialTable
            columns={columns}
            icons={tableIcons}
            data={data}
            options={tableOptions}
            isLoading={isLoading}
            components={{
              Pagination: (props) => <CPagination {...props} />,
            }}
          />
        </Box>
      </Paper>
    </Container>
  </>
}